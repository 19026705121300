<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    max-width="600px"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        depressed
        dense
        v-bind="attrs"
        v-on="on"
      >
        Update Status
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">Update Shipment Status</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
            >
              <v-select
                v-model="status"
                :items="statusList"
                item-text="name"
                item-value="value"
                hide-details
                label="Status"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="isDialogVisible = false"
          :loading="loading"
        >
          Cancel
        </v-btn>
        <v-btn
          color="success"
          @click="handleUpdateStatus"
          :loading="loading"
          :disabled="loading"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { updateStatus } from '@/api/shipping/shipping-order';
import { notifyErrors } from '@/assets/js/utils';
import { onMounted, ref } from '@vue/composition-api';

export default {
  props: {
    shipment: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const isDialogVisible = ref(false)
    const status = ref('preparing_for_shipment')

    const statusList = ref([
      {
        value: 'preparing_for_shipment',
        name: 'Preparing For Shipment',
      },
      {
        value: 'shipped',
        name: 'Shipped',
      },
      {
        value: 'in_transit',
        name: 'In Transit',
      },
      {
        value: 'delivering',
        name: 'Delivering',
      },
      {
        value: 'delivered',
        name: 'Delivered',
      },
      {
        value: 'completed',
        name: 'Completed',
      },
    ])

    onMounted(() => {
      status.value = props.shipment.status
    })

    const handleUpdateStatus = async () => {
      try {
        loading.value = true
        const response = await updateStatus(props.shipment.id, status.value)

        if (response) {
          isDialogVisible.value = false

          emit('updated')
        }
      } catch (error) {
        notifyErrors(error)
      } finally {
        loading.value = false
      }
    }

    return {
      loading,
      isDialogVisible,

      status,
      statusList,

      handleUpdateStatus,
    }
  },
}
</script>
