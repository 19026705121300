import { notifyErrors } from '@/assets/js/utils'
import { fetchOrder } from '@api/order'
import { ref } from '@vue/composition-api'

export default function useOrderView() {
  const orderData = ref(null)
  const loading = ref(false)

  // fetch data
  const loadOrder = id => {
    orderData.value = null
    loading.value = true

    return fetchOrder(id)
      .then(res => {
        orderData.value = res.data.data

        return res
      })
      .catch(notifyErrors)
      .finally(() => {
        loading.value = false
      })
  }

  return {
    loading,

    orderData,

    loadOrder,
  }
}
