<template>
  <v-data-table
    :headers="tableHeaders"
    :items="shippingOrders"
  >
    <template #[`item.tracking_number`]="{item}">
      <span v-if="item.tracking_number != null">{{ item.tracking_number }}</span>
      <span v-else>-</span>
    </template>

    <template #[`item.tracking_url`]="{item}">
      <a :href="item.tracking_url" v-if="item.tracking_url != null" target="_blank">Click me</a>
      <span v-else>-</span>
    </template>

    <template v-slot:item.waybill_url="{ item }">
      <a :href="item.waybill_url" download v-if="item.waybill_url != null">Download</a>
      <span v-else>-</span>
    </template>


    <template v-slot:item.final_shipping_fee="{ item }">
      <div v-if="item.final_shipping_fee == null">-</div>
      <div v-else>{{ item.final_shipping_fee }}</div>
    </template>

    <template v-slot:item.status="{ item }">
      <v-chip
        class="v-chip-light-bg"
        small
      >
        {{ item.status }}
      </v-chip>
    </template>


    <template #[`item.actions`]="{ item }">
      <v-menu
        bottom
        left
        :close-on-content-click="false"
        v-if="showActionMenu(item)"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-if="item.channel == 'merchant'">
            <v-list-item-title>
              <v-icon
                size="20"
                class="me-2"
              >
                {{ icons.mdiTruck }}
              </v-icon>

              <update-status
                :shipment="item"
                @updated="handleShippingStatusUpdated"
              />

            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="item.channel == 'gateway'">
            <v-list-item-title>
              <v-icon
                size="20"
                class="me-2"
              >
                {{ icons.mdiPhoneOutline }}
              </v-icon>
              <span>
                <v-btn
                  depressed
                  dense
                  @click="handlePickupRequest(item.id)"
                  :loading="sendingPickupRequest"
                >Call Pickup</v-btn></span>
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="cancellable(item)">
            <v-list-item-title>
              <v-icon
                size="20"
                class="me-2"
              >
                {{ icons.mdiCancel }}
              </v-icon>
              <span>
                <v-btn
                  depressed
                  dense
                  @click="handleCancelOrder(item.id)"
                  :loading="cancellingOrder"
                >
                  Cancel
                </v-btn>
              </span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import { cancelOrder, requestPickup } from '@/api/shipping/shipping-order'
import { confirm, notifySuccess } from '@/assets/js/utils'
import UpdateStatus from '@/modules/shipment/components/UpdateStatus.vue'
import { mdiCancel, mdiDotsVertical, mdiPhoneOutline, mdiTruck } from '@mdi/js'
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  props: {
    shippingOrders: Array,
    required: true,
  },
  components: {
    UpdateStatus,
  },
  setup(props, { emit }) {
    const sendingPickupRequest = ref(false)
    const cancellingOrder = ref(false)

    const tableHeaders = ref([
      { text: '#', value: 'id' },
      { text: 'Ship Via', value: 'channel' },
      { text: 'Tracking Number', value: 'tracking_number' },
      { text: 'Tracking URL', value: 'tracking_url' },
      { text: 'Waybill', value: 'waybill_url' },
      { text: 'Estimated Shipping Fee', value: 'estimated_shipping_fee' },
      { text: 'Final Shipping Fee', value: 'final_shipping_fee' },
      { text: 'Service Fee', value: 'service_fee' },
      { text: 'Status', value: 'status' },
      { text: 'Actions', value: 'actions', sortable: false },
    ])

    const handlePickupRequest = async id => {
      await confirm({
        title: 'Confirm sending pickup request?',
        content: 'Order cannot be cancelled after pickup request sent!',
      })

      sendingPickupRequest.value = true

      const response = await requestPickup(id)

      if (response) {
        emit('updated')

        notifySuccess({
          content: 'Pick up request sent',
        })
      }

      sendingPickupRequest.value = false
    }

    const handleCancelOrder = async id => {
      await confirm({
        title: 'Confirm cancel this order?',
      })

      cancellingOrder.value = true

      const response = await cancelOrder(id)

      if (response) {
        emit('updated')

        notifySuccess({
          content: 'Shipping order cancelled',
        })
      }

      cancellingOrder.value = false
    }

    const handleShippingStatusUpdated = () => {
      emit('updated')

      notifySuccess({
        content: 'Shipping status updated',
      })
    }

    const showActionMenu = item => {
      if (item.channel === 'merchant' && item.status !== 'completed') {
        return true
      }

      if (item.channel === 'gateway' && item.status === 'preparing_for_shipment') {
        return true
      }

      return false
    }

    const cancellable = item => {
      return item.status === 'preparing_for_shipment'
    }

    return {
      icons: {
        mdiDotsVertical,
        mdiPhoneOutline,
        mdiCancel,
        mdiTruck,
      },

      tableHeaders,
      sendingPickupRequest,
      handlePickupRequest,
      cancellingOrder,
      handleCancelOrder,

      // Shipping
      handleShippingStatusUpdated,
      showActionMenu,
      cancellable,
    }
  },
})
</script>
