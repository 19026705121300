<template>
  <v-row>
    <v-col cols="12">
      <v-card class="mb-7">
        <v-card-text>
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="primary"
            class="mb-3"
            grow
          >
            <v-tab>
              Items
            </v-tab>

            <v-tab v-if="order.nature == 'tangible'">
              Shipping Detail
            </v-tab>

            <v-tab v-if="order.nature == 'tangible' && order.shipping_orders && order.shipping_orders.length > 0">
              Shipments
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-data-table
                v-model="selectedRows"
                :headers="tableColumnHeaders"
                :items="order.items"
                :items-per-page="99"
                :show-select="!!hasAction"
                hide-default-footer
              >
                <!-- name -->
                <template #[`item.name`]="{item}">
                  <div class="align-center">
                    <router-link
                      class="text--primary font-weight-semibold text-decoration-none"
                      :to="{ name: 'product-view', params: { id: item.sku.product_id } }"
                    >
                      {{ t(item.sku_snapshot.product_title) }}
                    </router-link>
                    |
                    <router-link
                      class="text--primary font-weight-semibold text-decoration-none"
                      :to="{ name: 'product-view', params: { id: item.sku.product_id }, query: { tab: 'SKUS' } }"
                    >
                      {{ t(item.sku_snapshot.sku_name) }}
                    </router-link>
                    <span v-if="item.inventory_snapshot && item.inventory_snapshot.info">
                      {{ item.inventory_snapshot.info.started_at | dateSimple }}
                    </span>
                  </div>
                </template>

                <!-- status -->
                <template #[`item.status`]="{item}">
                  <v-chip
                    :color="[ 'confirmed', 'completed' ].includes(item.status) ? 'success' : ''"
                    x-small
                  >
                    {{ item.status }}
                  </v-chip>
                </template>

                <!-- price -->
                <template #[`item.price`]="{item}">
                  <span>
                    {{ item.price | price }}
                  </span>
                </template>

                <!-- quantity -->
                <template #[`item.quantity`]="{item}">
                  <span>
                    {{ item.quantity }}
                  </span>
                </template>

                <!-- subtotal -->
                <template #[`item.subtotal`]="{item}">
                  <span>
                    {{ item.subtotal | price }}
                  </span>
                </template>
              </v-data-table>

              <div
                class="text-right mt-5"
              >
                <span
                  v-show="confirmableItems.length"
                  class="ml-2"
                >
                  <v-btn
                    :disabled="!selectedRows.length || !items.cancellable"
                    :loading="loading"
                    @click="confirmCancelOrder"
                  >
                    Cancel Items
                  </v-btn>
                </span>

                <span
                  v-show="confirmableItems.length"
                  class="ml-2"
                >
                  <v-btn
                    :disabled="!selectedRows.length || !items.confirmable"
                    color="primary"
                    :loading="loading"
                    @click="confirmItems"
                  >
                    Confirm Order
                  </v-btn>
                </span>

                <span
                  v-show="shippableItems.length > 0"
                  class="ml-2"
                >
                  <v-btn
                    color="primary"
                    :loading="loading"
                    @click="openShipmentDialog"
                  >
                    Create Shippment
                  </v-btn>
                </span>

                <span
                  v-show="completableItems.length"
                  class="ml-2"
                >
                  <v-btn
                    :disabled="!selectedRows.length || !items.completable"
                    color="primary"
                    :loading="loading"
                    @click="confirmCompleteItems"
                  >
                    Complete Order
                  </v-btn>
                </span>

                <span
                  v-show="pickupableItems.length"
                  class="ml-2"
                >
                  <v-btn
                    :disabled="!selectedRows.length || !items.pickupable"
                    color="primary"
                    :loading="loading"
                    @click="confirmReadyForPickup"
                  >
                    Ready for Pickup
                  </v-btn>
                </span>

                <span
                  v-show="pickupCompletableItems.length"
                  class="ml-2"
                >
                  <v-btn
                    :disabled="!selectedRows.length || !items.pickupCompletable"
                    color="primary"
                    :loading="loading"
                    @click="confirmCompletePickup"
                  >
                    Complete Pickup
                  </v-btn>
                </span>
                <p
                  v-show="hasAction && !selectedRows.length && !selectedWaitItems.length"
                  class="mt-2"
                >
                  Please select at least one item to continue.
                </p>
              </div>
            </v-tab-item>

            <v-tab-item>
              <shipping-detail-table :shippings="order.shippings" />
            </v-tab-item>

            <v-tab-item>
              <shipping-order-table
                :shipping-orders="order.shipping_orders"
                @updated="handleShippingOrderUpdate"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { cancelOrder, completeOrder, completePickupOrder, confirmOrderItems, readyForPickupOrder } from '@/api/order'
import { confirm, notifySuccess } from '@/assets/js/utils'
import ShippingDetailTable from '@/modules/shipment/components/ShippingDetailTable.vue'
import ShippingOrderTable from '@/modules/shipment/components/ShippingOrderTable.vue'
import { t } from '@/plugins/i18n'
import { dateSimple, price } from '@core/utils/filter'
import { mdiPlus, mdiTruck } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

export default {
  filters: { price, dateSimple },
  components: { ShippingDetailTable, ShippingOrderTable },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const tab = ref(null)

    const tableColumnHeaders = [
      { text: 'NAME', value: 'name', sortable: false },
      { text: 'UNIT PRICE', value: 'price', sortable: false },
      { text: 'QUANTITY', value: 'quantity', sortable: false },
      { text: 'TOTAL', value: 'subtotal', sortable: false },
      { text: 'STATUS', value: 'status', sortable: false },

      // { text: 'ACTION', value: 'action', sortable: false },
    ]

    const selectedRows = ref([])
    const loading = ref(false)

    const confirmableItems = computed(() => props.order.items.filter(item => item.status === 'wait_for_confirmation'))
    const completableItems = computed(() => (props.order.nature === 'service' ? props.order.items.filter(item => item.status === 'confirmed') : []))
    const shippableItems = computed(() =>
      props.order.nature === 'tangible' &&
      props.order.shippings.find(s => s.destination_type.includes('UserAddress') || s.destination_type.includes('UserPickup'))
        ? props.order.items.filter(item => item.status === 'confirmed')
        : [],
    )
    const pickupableItems = computed(() =>
      props.order.nature === 'tangible' && props.order.shippings.find(s => s.destination_type.includes('UserBranch'))
        ? props.order.items.filter(item => item.status === 'confirmed')
        : [],
    )
    const pickupCompletableItems = computed(() =>
      props.order.nature === 'tangible' && props.order.shippings.find(s => s.destination_type.includes('UserBranch'))
        ? props.order.items.filter(item => item.status === 'ready_for_pickup')
        : [],
    )
    const selectedWaitItems = computed(() => selectedRows.value.filter(item => item.status === 'wait_for_confirmation'))
    const selectedCompletableItems = computed(() => selectedRows.value.filter(item => item.status === 'confirmed'))
    const selectedPickupableItems = computed(() => selectedRows.value.filter(item => item.status === 'confirmed'))
    const selectedPickupCompletableItems = computed(() => selectedRows.value.filter(item => item.status === 'ready_for_pickup'))

    const items = computed(() => ({
      cancellable: selectedWaitItems.value.length === selectedRows.value.length,
      confirmable: selectedWaitItems.value.length === selectedRows.value.length,
      completable: completableItems.value.length && selectedCompletableItems.value.length === selectedRows.value.length,
      pickupable: pickupableItems.value.length && selectedPickupableItems.value.length === selectedRows.value.length,
      pickupCompletable: pickupCompletableItems.value.length && selectedPickupCompletableItems.value.length === selectedRows.value.length,
    }))

    const hasAction = computed(
      () =>
        completableItems.value.length ||
        confirmableItems.value.length ||
        shippableItems.value.length ||
        selectedWaitItems.value.length ||
        pickupableItems.value.length ||
        pickupCompletableItems.value.length,
    )

    const namesInAction = computed(() =>
      selectedRows.value.map(item => `${t(item.sku_snapshot.product_title)} - ${t(item.sku_snapshot.sku_name)}`).join('<br>'),
    )

    const confirmItems = async () => {
      await confirm({
        title: 'Confirm These Items?',
        content: namesInAction.value,
      })

      loading.value = true
      await confirmOrderItems(
        props.order.id,
        selectedRows.value.map(oi => oi.id),
      ).finally(() => {
        loading.value = false
      })

      emit('updated')
      notifySuccess({ content: 'Order Items Confirmed!' })
    }

    const confirmCancelOrder = async () => {
      await confirm({
        title: 'Confirm Cancel?',
        content: namesInAction.value,
      })

      loading.value = true
      await cancelOrder(
        props.order.id,
        selectedRows.value.map(oi => oi.id),
      ).finally(() => {
        loading.value = false
      })

      emit('updated')
      notifySuccess({ content: 'Order is Cancelled.' })
    }

    const confirmCompleteItems = async () => {
      await confirm({
        title: 'Confirm Complete?',
        content: namesInAction.value,
      })

      loading.value = true
      await completeOrder(
        props.order.id,
        selectedRows.value.map(oi => oi.id),
      ).finally(() => {
        loading.value = false
      })

      emit('updated')
      notifySuccess({ content: 'Order is Completed.' })
    }

    const confirmReadyForPickup = async () => {
      await confirm({
        title: 'Confirm Items Ready for Pickup?',
        content: namesInAction.value,
      })

      loading.value = true
      await readyForPickupOrder(
        props.order.id,
        selectedRows.value.map(oi => oi.id),
      ).finally(() => {
        loading.value = false
      })

      emit('updated')
      notifySuccess({ content: 'Items are ready to Pickup.' })
    }

    const confirmCompletePickup = async () => {
      await confirm({
        title: 'Confirm Customers have successfully picked up?',
        content: namesInAction.value,
      })

      loading.value = true
      await completePickupOrder(
        props.order.id,
        selectedRows.value.map(oi => oi.id),
      ).finally(() => {
        loading.value = false
      })

      emit('updated')
      notifySuccess({ content: 'Pickup Items are completed.' })
    }

    // Shipment dialog
    const openShipmentDialog = () => {
      emit('openShipmentDialog', {
        orderId: props.order.id,
      })
    }

    const handleShippingOrderUpdate = () => {
      emit('updated')
    }

    return {
      tab,
      tableColumnHeaders,
      t,
      items,
      selectedRows,
      selectedWaitItems,
      confirmableItems,
      completableItems,
      shippableItems,
      pickupableItems,
      pickupCompletableItems,
      hasAction,

      confirmItems,
      confirmCancelOrder,
      confirmCompleteItems,
      confirmReadyForPickup,
      confirmCompletePickup,
      loading,

      icons: {
        mdiPlus,
        mdiTruck,
      },

      // Shipment
      openShipmentDialog,
      handleShippingOrderUpdate,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
